import React from 'react'

function PrivacyPolicy() {

    return (
        <div className='container'>
            <p>
                1agent built the 1AGENT app as
                a Free app. This SERVICE is provided by <span style={{fontWeight: 'bold'}}>Moovar software solutions</span> at no cost and is intended for use as
                is.
            </p> <p>
                This page is used to inform visitors regarding my
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use my Service.
            </p> <p>
                If you choose to use my Service, then you agree to
                the collection and use of information in relation to this
                policy. The Personal Information that I collect is
                used for providing and improving the Service. I will not use or share your information with
                anyone except as described in this Privacy Policy.
            </p> <p>
                The terms used in this Privacy Policy have the same meanings
                as in our Terms and Conditions, which are accessible at
                1AGENT unless otherwise defined in this Privacy Policy.
            </p> <p><strong>Information Collection and Use</strong></p> <p>
                For a better experience, while using our Service, I
                may require you to provide us with certain personally
                identifiable information, including but not limited to Premium Calculator. The information that
                I request will be retained on your device and is not collected by me in any way.
            </p> <div><p>
                The app does use third-party services that may collect
                information used to identify you.
            </p> <p>
                    Link to the privacy policy of third-party service providers used
                    by the app
                </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li></ul>
            </div> <p><strong>Cookies</strong></p> <p>
                Cookies are files with a small amount of data that are
                commonly used as anonymous unique identifiers. These are sent
                to your browser from the websites that you visit and are
                stored on your device's internal memory.
            </p> <p>
                This Service does not use these “cookies” explicitly. However,
                the app may use third-party code and libraries that use
                “cookies” to collect information and improve their services.
                You have the option to either accept or refuse these cookies
                and know when a cookie is being sent to your device. If you
                choose to refuse our cookies, you may not be able to use some
                portions of this Service.
            </p> <p><strong>Service Providers</strong></p> <p>
                I may employ third-party companies and
                individuals due to the following reasons:
            </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                I want to inform users of this Service
                that these third parties have access to their Personal
                Information. The reason is to perform the tasks assigned to
                them on our behalf. However, they are obligated not to
                disclose or use the information for any other purpose.
            </p> <p><strong>Security</strong></p> <p>
                I value your trust in providing us your
                Personal Information, thus we are striving to use commercially
                acceptable means of protecting it. But remember that no method
                of transmission over the internet, or method of electronic
                storage is 100% secure and reliable, and I cannot
                guarantee its absolute security.
            </p> <p><strong>Changes to This Privacy Policy</strong></p> <p>
                I may update our Privacy Policy from
                time to time. Thus, you are advised to review this page
                periodically for any changes. I will
                notify you of any changes by posting the new Privacy Policy on
                this page.
            </p> <p><strong>Contact Us</strong></p> <p>
                If you have any questions or suggestions about my
                Privacy Policy, do not hesitate to contact me at support @1agent.in.
            </p>
        </div>
    )
}

export default PrivacyPolicy;
