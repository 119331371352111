import './App.scss';
import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import { Container } from 'react-bootstrap'
import { Nav } from 'react-bootstrap'
import Footer from './component/Footer';
import Contact from './component/Contact';
import PrivacyPolicy from './component/PrivacyPolicy';
import Terms from './component/Terms';
import Home from './component/Home';

export default function App() {

  React.useEffect(() => {
    if (window.location.hash) {
      const e = document.querySelector(window.location.hash);
      if (e)
        e.scrollIntoView();
    }
  }, []);

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" variant="dark" className="bg-appcolor" id='my-scroll'>
        <Container>
          <Navbar.Brand href='#home'>
            <img width='75' src='/assets/logo.svg' alt='Logo'/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto ">
              <Nav.Link href='#home' className="text-white"><b>Home</b></Nav.Link>
              <Nav.Link href='#terms' className="text-white"><b>Terms</b></Nav.Link>
              <Nav.Link href='#privacyPolicy' className="text-white"><b>Privacy Policy</b></Nav.Link>
              <Nav.Link href='#contact' className="text-white"><b>Contact</b></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <main className='content' id='content'>
        <section id='home'>
          <Home></Home>
        </section>
        <section className="mt-3" id='privacyPolicy'>
          <h2 className="text-center mb-4"> Privacy Policy </h2>
          <PrivacyPolicy></PrivacyPolicy>
        </section>
        <section className="mt-3" id='terms'>
          <h2 className="text-center mb-4"> Terms & Conditons </h2>
          <Terms></Terms>
        </section>
        <section className="mt-3" id='contact'>
          <h2 className="text-center mb-4"> Contact Us </h2>
          <Contact></Contact>
        </section>
        <section className="mt-3">
          <Footer></Footer>
        </section>
      </main>
    </div>
  )
}