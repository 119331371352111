import React from 'react'
import { Container } from 'react-bootstrap'

function Home() {

    return (
        <Container>
            <div className='d-flex justify-content-end'>
                <a href='https://play.google.com/store/apps/details?id=com.app_1agent'>
                    <img width="300" src='/assets/playstore.svg' alt='Playstore' />
                </a>
            </div>
        </Container>
    )
}

export default Home
