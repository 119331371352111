import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FaEnvelope } from "react-icons/fa"

function Footer() {
    return (
        <div className="bg-appcolor footer">
            <Container className=" pt-90 pb-60">
                <Row className="text-white">
                    <Col md={8}>
                        <div className="horizontal-contact mt-30 pt-30">
                            <Row className="text-center">
                                <Col md={12} className="mt-3 mt-sm-0">
                                    <div className="each-widget text-white"> <FaEnvelope />
                                        <div className="footer_title mt-3">Email</div>
                                        <p className="mt-1"><a href="mailto:support@1agent.in" target="_blank" rel="noopener noreferrer"
                                            className="text-white text-decoration-none">support@1agent.in</a></p>
                                        <p>Copyright © 2021 1AGENT | All Rights Reserved | Designed by <a href="https://1agent.in/" target="_blank" rel="noreferrer"
                                            className="text-white text-decoration-none">
                                            1AGENT</a></p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='d-flex justify-content-end'>
                            <a href='https://play.google.com/store/apps/details?id=com.app_1agent'>
                                <img width="300" src='/assets/playstore.svg' alt='Playstore' />
                            </a>
                        </div>
                    </Col>
                </Row>
                {/* <Row className="text-center text-white">
                    <Col>
                        <p>Copyright © 2021 1AGENT | All Rights Reserved | Designed by <a href="https://1agent.in/" target="_blank" rel="noreferrer"
                            className="text-white text-decoration-none">
                            1AGENT</a></p>
                    </Col>
                </Row> */}
            </Container>
        </div>

    )
}

export default Footer
