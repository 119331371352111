import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'
import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap'


const validate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = "Name is required";
    } else if (values.name.length > 30) {
        errors.name = "Must be 30 characters or less";
    }
    if (!values.email) {
        errors.email = "Email is required";
    } else if (!/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(values.email)) {
        errors.email = "Invalid email address";
    }

    if (values.message.length > 1000) {
        errors.message = "Must be 1000 characters or less"
    }

    return errors;
}

export default function Contact() {
    const [popup, showPopup] = useState(false);
    const [error] = useState('Something failed. Please try again after some time.');
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: ''
        },
        validate,
        onSubmit: (values, { resetForm }) => {
            fetch("/contact-us", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(response => {
                response.json().then(data => {
                    showPopup(true);
                    resetForm({ values: '' });
                }).catch(e => {
                    alert(error);
                    resetForm({ values: '' });
                });
            }).catch(e => {
                showPopup(false);
                alert(error);
                resetForm({ values: '' });
            });
        },
    });
    const handleClose = () => {
        showPopup(false);
    }
    return (
        <div>
            <form onSubmit={formik.handleSubmit} id="form" encType="multipart/form-data" method="post" noValidate>
                <Container>
                    <Form.Floating className="mb-3">
                        <Form.Control
                            id="floatingPasswordCustom"
                            type="text"
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            onBlur={formik.handleBlur}
                            required
                        />
                        {
                            formik.touched.name && formik.errors.name ? <span className="text-danger">{formik.errors.name} </span> : null

                        }
                        <label htmlFor="floatingPasswordCustom">Name<span className="text-danger ms-1">*</span></label>
                    </Form.Floating>

                    <Form.Floating className="mb-3">
                        <Form.Control
                            id="floatingInputCustom"
                            type="email"
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            required
                        />
                        {
                            formik.touched.email && formik.errors.email ? <span className="text-danger">{formik.errors.email} </span> : null

                        }
                        <label htmlFor="floatingInputCustom">Email address<span className="text-danger ms-1">*</span></label>
                    </Form.Floating>
                    <FloatingLabel controlId="floatingTextarea2" label="Message" className="mb-3">
                        <Form.Control
                            as="textarea"
                            style={{ height: '100px' }}
                            name="message"
                            onChange={formik.handleChange}
                            value={formik.values.message}

                        />
                        <Form.Text id="passwordHelpBlock" muted>
                            Message should not exceed 1000 characters
                        </Form.Text>
                        <div>
                            {
                                formik.touched.message && formik.errors.message ? <span className="text-danger">{formik.errors.message} </span> : null

                            }
                        </div>
                    </FloatingLabel>

                    <div className="mt-2">
                        <Button disabled={formik.isSubmitting} variant="success float-right" type="submit">Submit</Button>
                    </div>
                </Container>
            </form>
            <Modal show={popup} centered={true}>
                <Modal.Header closeButton onHide={handleClose} >
                    <Modal.Title>THANK YOU</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    We received your request, We will reach you shortly to your registered email address.
                </Modal.Body>
                <Modal.Footer>
                    <h5 className='cp' onClick={handleClose}><strong>OK</strong></h5>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


